import React from "react";

export { Header, Nav, Footer };

function Header(props) {
  return (
    <header className="main-head vertical-center">
      <div className="pale-peach border-peach dashed h2">S T O R Y</div>
      <div className="pale-robin h2">S E A</div>
    </header>
  );
}

function Nav(props) {
  return (
    <nav className="main-nav vertical-center">
      <div>
        <p>user profile | storage | character creation</p>
      </div>
      <div className="gateway">
        welcome <b>{props.name}</b>!
      </div>
    </nav>
  );
}

function Footer(props) {
  return (
    <footer className="main-footer vertical-center">
      <div>terms of use | privacy policy | contact us</div>
    </footer>
  );
}
