const ITEMS = new Map();

const eyes = {
  e4blue: {
    id: "e4blue",
    type: "eyes",
    name: "blue eyes",
    img: "e/e4-blue.png",
    index: "z2",
    thumb: "e/e4t-blue.png"
  },
  e4purple: {
    id: "e4purple",
    type: "eyes",
    name: "purple eyes",
    img: "e/e4-purple.png",
    index: "z2",
    thumb: "e/e4t-purple.png"
  },
  e4bpink: {
    id: "e4bpink",
    type: "eyes",
    name: "bright pink eyes",
    img: "e/e4-bpink.png",
    index: "z2",
    thumb: "e/e4t-bpink.png"
  },
  e4mystery: {
    id: "e4mystery",
    type: "eyes",
    name: "mysterious eyes",
    img: "e/e4-mystery.png",
    index: "z2",
    thumb: "e/e4t-mystery.png"
  },
  e4red: {
    id: "e4red",
    type: "eyes",
    name: "red eyes",
    img: "e/e4-red.png",
    index: "z2",
    thumb: "e/e4t-red.png"
  },
  e4green: {
    id: "e4green",
    type: "eyes",
    name: "green eyes",
    img: "e/e4-green.png",
    index: "z2",
    thumb: "e/e4t-green.png"
  },
  e4redder: {
    id: "e4redder",
    type: "eyes",
    name: "redder eyes",
    img: "e/e4-redder.png",
    index: "z2",
    thumb: "e/e4t-redder.png"
  },
  e4dreampurple: {
    id: "e4dreampurple",
    type: "eyes",
    name: "dream purple eyes",
    img: "e/e4-dreampurple.png",
    index: "z2",
    thumb: "e/e4t-dreampurple.png"
  }
};

const skin = {
  d6: {
    id: "d6",
    type: "skin",
    name: "pale skin",
    img: "d/d6.png",
    index: "z1",
    thumb: "d/d6t.png"
  },
  d1: {
    id: "d1",
    type: "skin",
    name: "milk chocolate skin",
    img: "d/d1.png",
    index: "z1",
    thumb: "d/d1t.png"
  },
  d2: {
    id: "d2",
    type: "skin",
    name: "tan skin",
    img: "d/d2.png",
    index: "z1",
    thumb: "d/d2t.png"
  },
  d3: {
    id: "d3",
    type: "skin",
    name: "anime skin",
    img: "d/d3.png",
    index: "z1",
    thumb: "d/d3t.png"
  },
  d0: {
    id: "d0",
    type: "skin",
    name: "peach skin",
    img: "d/d0.png",
    index: "z1",
    thumb: "d/d0t.png"
  },
  d4: {
    id: "d4",
    type: "skin",
    name: "very pale skin",
    img: "d/d4.png",
    index: "z1",
    thumb: "d/d4t.png"
  },
  d5: {
    id: "d5",
    type: "skin",
    name: "mocha skin",
    img: "d/d5.png",
    index: "z1",
    thumb: "d/d5t.png"
  },
  d7: {
    id: "d7",
    type: "skin",
    name: "mocha skin",
    img: "d/d7.png",
    index: "z1",
    thumb: "d/d7t.png"
  },
  d8: {
    id: "d8",
    type: "skin",
    name: "olive skin",
    img: "d/d8.png",
    index: "z1",
    thumb: "d/d8t.png"
  },
  d9: {
    id: "d9",
    type: "skin",
    name: "ice skin",
    img: "d/d9.png",
    index: "z1",
    thumb: "d/d9t.png"
  },
  d10: {
    id: "d10",
    type: "skin",
    name: "demon skin",
    img: "d/d10.png",
    index: "z1",
    thumb: "d/d10t.png"
  },
  d11: {
    id: "d11",
    type: "skin",
    name: "dark teal skin",
    img: "d/d11.png",
    index: "z1",
    thumb: "d/d11t.png"
  },
  d12: {
    id: "d12",
    type: "skin",
    name: "golden skin",
    img: "d/d12.png",
    index: "z1",
    thumb: "d/d12t.png"
  },
  d13: {
    id: "d13",
    type: "skin",
    name: "pale olive skin",
    img: "d/d13.png",
    index: "z1",
    thumb: "d/d13t.png"
  },
  d14: {
    id: "d14",
    type: "skin",
    name: "medium skin",
    img: "d/d14.png",
    index: "z1",
    thumb: "d/d14t.png"
  },
  d15: {
    id: "d15",
    type: "skin",
    name: "dark brown skin",
    img: "d/d15.png",
    index: "z1",
    thumb: "d/d15t.png"
  },
  d16: {
    id: "d16",
    type: "skin",
    name: "dark coffee skin",
    img: "d/d16.png",
    index: "z1",
    thumb: "d/d16t.png"
  },
  d17: {
    id: "d17",
    type: "skin",
    name: "pale pale skin",
    img: "d/d17.png",
    index: "z1",
    thumb: "d/d17t.png"
  },
  d18: {
    id: "d18",
    type: "skin",
    name: "medium chestnut skin",
    img: "d/d18.png",
    index: "z1",
    thumb: "d/d18t.png"
  },
  d19: {
    id: "d19",
    type: "skin",
    name: "dark cold skin",
    img: "d/d19.png",
    index: "z1",
    thumb: "d/d19t.png"
  },
  d20: {
    id: "d20",
    type: "skin",
    name: "medium coffee skin",
    img: "d/d20.png",
    index: "z1",
    thumb: "d/d20t.png"
  }
};

const hair = {
  h1brown: {
    id: "h1brown",
    type: "hair",
    name: "brown hair",
    img: "h/h1-brown.png",
    index: "z2",
    thumb: "h/h1t-brown.png"
  },
  h1cherry: {
    id: "h1cherry",
    type: "hair",
    name: "cherry hair",
    img: "h/h1-cherry.png",
    index: "z2",
    thumb: "h/h1t-cherry.png"
  },
  h1aqua: {
    id: "h1aqua",
    type: "hair",
    name: "aqua hair",
    img: "h/h1-aqua.png",
    index: "z2",
    thumb: "h/h1t-aqua.png"
  },
  h1dred: {
    id: "h1dred",
    type: "hair",
    name: "dark red hair",
    img: "h/h1-dred.png",
    index: "z2",
    thumb: "h/h1t-dred.png"
  },
  h1dgreen: {
    id: "h1dgreen",
    type: "hair",
    name: "dark green hair",
    img: "h/h1-dgreen.png",
    index: "z2",
    thumb: "h/h1t-dgreen.png"
  },
  h1black: {
    id: "h1black",
    type: "hair",
    name: "black hair",
    img: "h/h1-black.png",
    index: "z2",
    thumb: "h/h1t-black.png"
  },
  h2purple: {
    id: "h2purple",
    type: "hair",
    name: "dark purple hair",
    img: "h/h2-purple.png",
    index: "z3",
    thumb: "h/h2t-purple.png"
  },
  h2bluegreen: {
    id: "h2bluegreen",
    type: "hair",
    name: "blue green hair",
    img: "h/h2-bluegreen.png",
    index: "z3",
    thumb: "h/h2t-bluegreen.png"
  },
  h3blue: {
    id: "h3blue",
    type: "hair",
    name: "blue  hair",
    img: "h/h3-blue.png",
    index: "z3",
    thumb: "h/h3t-blue.png"
  },
  h3orange: {
    id: "h3orange",
    type: "hair",
    name: "orange  hair",
    img: "h/h3-orange.png",
    index: "z3",
    thumb: "h/h3t-orange.png"
  }
};
const top = {
  t1black: {
    id: "t1black",
    type: "top",
    name: "black tank top",
    img: "t/t1black.png",
    index: "z2",
    thumb: "t/t1blackt.png"
  },
  t1blue: {
    id: "t1blue",
    type: "top",
    name: "blue tank top",
    img: "t/t1blue.png",
    index: "z2",
    thumb: "t/t1bluet.png"
  }
};
const bottom = {
  b0black: {
    id: "b0black",
    type: "bottom",
    name: "black short shorts",
    img: "b/b0black.png",
    index: "z2",
    thumb: "b/b0blackt.png"
  },
  b0grey: {
    id: "b0grey",
    type: "bottom",
    name: "grey short shorts",
    img: "b/b0grey.png",
    index: "z2",
    thumb: "b/b0greyt.png"
  },
  b0blue: {
    id: "b0blue",
    type: "bottom",
    name: "blue short shorts",
    img: "b/b0blue.png",
    index: "z2",
    thumb: "b/b0bluet.png"
  }
};
const shoes = {};
const accessories = {};
const misc = {
  na: {
    id: "na",
    type: "eye",
    name: "na",
    img: "misc/na.png",
    index: "z2",
    thumb: "misc/na.png"
  }
};
ITEMS.set("eyes", eyes);
ITEMS.set("skin", skin);
ITEMS.set("hair", hair);
ITEMS.set("top", top);
ITEMS.set("bottom", bottom);
ITEMS.set("shoes", shoes);
ITEMS.set("accessories", accessories);
ITEMS.set("misc", misc);

export { ITEMS };
