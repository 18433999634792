import React, { useState, useEffect } from "react";
import Popup from "reactjs-popup";
import { ITEMS } from "../item/item.js";

export { CharacterCreation };

function CharacterCreation(props) {
  const [name, setName] = useState("NEW_NAME");
  const [equip, setEquip] = useState(props.currentEquip);
  const addNewEquip = e => {
    let newEquip = [];
    for (let i in equip) {
      let item = equip[i];
      // newEquip.push(item);
      if (item.type != "accessories") {
        if (item.type != e["type"]) {
          newEquip.push(item);
        }
      } else {
        newEquip.push(item);
      }
    }
    console.log(newEquip);
    newEquip.push({ type: e["type"], id: e["id"] });
    setEquip(newEquip);
  };
  const removeEquip = () => {
    let newEquip = [];
    setEquip(newEquip);
  };
  return (
    <div className="creation-container">
      <div className="character-name vertical-center h4">
        {/* <p>{name}</p> */}
      </div>
      <div className="character">
        <img
          className={"resized z0"}
          src={require("../../assets/d/base.png")}
          key={"base"}
          alt={"base"}
        />
        <Doll equipment={equip} />
      </div>
      <Inventory
        inventory={props.inventory}
        equipItem={addNewEquip}
        removeItem={removeEquip}
      />
    </div>
  );
}

function Inventory(props) {
  const [selected, setSelected] = useState("skin");

  const Modal = () => (
    <Popup
      trigger={<i className="robin fa-lg fa fa-save save button" />}
      modal
      closeOnDocumentClick
      onOpen={() => saveCharacter()}
    >
      <span> saved ♥ </span>
    </Popup>
  );

  var inventoryNav = (
    <>
      <p className="nav" onClick={() => setSelected("skin")}>
        skin　
      </p>
      <p>|</p>
      <p className="nav" onClick={() => setSelected("eyes")}>
        　eyes　
      </p>
      <p>|</p>
      <p className="nav" onClick={() => setSelected("hair")}>
        　hair　
      </p>
      <p>|</p>
      <p className="nav" onClick={() => setSelected("top")}>
        　top　
      </p>
      <p>|</p>
      <p className="nav" onClick={() => setSelected("bottom")}>
        　bottom　
      </p>
      <p>|</p>
      <p className="nav" onClick={() => setSelected("shoes")}>
        　shoes　
      </p>
      <p>|</p>
      <p className="nav" onClick={() => setSelected("accessories")}>
        　accessories　
      </p>
      <p>|</p>
      <p className="nav" onClick={() => setSelected("misc")}>
        　misc　
      </p>
    </>
  );

  return (
    <div className="equip-container">
      <p className="equip-title">INVENTORY</p>
      <div className="equip-nav h4">
        {inventoryNav}
        <div className="icon vertical-center">
          <i
            className="robin fa-lg fa fa-ban ban"
            onClick={() => props.removeItem()}
          />
          <Modal />
        </div>
      </div>
      <div className="equip-box">
        <EquipBox
          inventory={props.inventory}
          selected={selected}
          equipItem={props.equipItem}
        />
      </div>
    </div>
  );
}

function EquipBox(props) {
  var skin = props.inventory.skin.map(i => (
    <Thumbnail item={ITEMS.get(i.type)[i.id]} equipItem={props.equipItem} />
  ));
  var eyes = props.inventory.eyes.map(i => (
    <Thumbnail item={ITEMS.get(i.type)[i.id]} equipItem={props.equipItem} />
  ));
  var hair = props.inventory.hair.map(i => (
    <Thumbnail item={ITEMS.get(i.type)[i.id]} equipItem={props.equipItem} />
  ));
  var top = props.inventory.top.map(i => (
    <Thumbnail item={ITEMS.get(i.type)[i.id]} equipItem={props.equipItem} />
  ));
  var bottom = props.inventory.bottom.map(i => (
    <Thumbnail item={ITEMS.get(i.type)[i.id]} equipItem={props.equipItem} />
  ));
  var accessories = props.inventory.accessories.map(i => (
    <Thumbnail item={ITEMS.get(i.type)[i.id]} equipItem={props.equipItem} />
  ));
  var shoes = props.inventory.shoes.map(i => (
    <Thumbnail item={ITEMS.get(i.type)[i.id]} equipItem={props.equipItem} />
  ));
  var misc = props.inventory.misc.map(i => (
    <Thumbnail item={ITEMS.get(i.type)[i.id]} equipItem={props.equipItem} />
  ));
  if (props.selected === "skin") return skin;
  if (props.selected === "eyes") return eyes;
  if (props.selected === "hair") return hair;
  if (props.selected === "top") return top;
  if (props.selected === "shoes") return shoes;
  if (props.selected === "bottom") return bottom;
  if (props.selected === "accessories") return accessories;
  if (props.selected === "misc") return misc;
  return <></>;
  // return "hello";
}

function Thumbnail(props) {
  // useEffect(() => {});
  let i = props.item;
  if (props.item["thumb"] === "") {
    return <></>;
  }
  return (
    <img
      // className={"resized"}
      src={require("../../assets/" + i["thumb"])}
      key={i["id"]}
      alt={i["name"]}
      onClick={() => props.equipItem(props.item)}
      // onHover={() => props.equipItem(props.item)}
    />
  );
}

function handleEquip(e) {
  console.log("clicked!" + e.name);
  // useEffect(() => {});
}

function saveCharacter(e) {
  console.log("clicked save character!");
}

function Doll(props) {
  // console.log(ITEMS.get("eyes"));
  // console.log(ITEMS);
  // console.log(props);
  // console.log(props.equipment);
  // var transform = i => {
  //   let category = ITEMS.get(i.type);
  //   let item = category[i.id];
  //   console.log(category);
  //   console.log(item);
  // };
  // props.equipment.map(transform);
  // for (item in props.equipment) {
  // console.log(item);
  // }
  let doll = props.equipment.map(i => (
    <img
      className={"resized " + ITEMS.get(i.type)[i.id]["index"]}
      src={require("../../assets/" + ITEMS.get(i.type)[i.id]["img"])}
      key={ITEMS.get(i.type)[i.id]["name"]}
      alt={ITEMS.get(i.type)[i.id]["name"]}
    />
  ));
  return doll;
  // let base = (
  //   <img
  //     className={"resized z0"}
  //     src={require("../../assets/base.png")}
  //     key={"base"}
  //     alt={"base"}
  //   />
  // );

  // return
  // (<>
  //   base
  //   doll
  //   </>
  // );
  // return <></>;
}
