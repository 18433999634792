import React from "react";

import { CharacterCreation } from "./js/creation/create.js";
import { Header, Nav, Footer } from "./js/structure/skeleton.js";

class StorySeaApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentEquip: [
        { type: "skin", id: "d2" },
        { type: "eyes", id: "e4green" },
        { type: "hair", id: "h1black" },
        { type: "top", id: "t1black" },
        { type: "bottom", id: "b0black" }
      ],
      inventory: {
        skin: [
          {
            type: "skin",
            id: "d6",
          },
          {
            type: "skin",
            id: "d4",
          },
          {
            type: "skin",
            id: "d1",
          },
          {
            type: "skin",
            id: "d0",
          },
          {
            type: "skin",
            id: "d5",
          },
          {
            type: "skin",
            id: "d2",
          },
          {
            type: "skin",
            id: "d3",
          },
          {
            type: "skin",
            id: "d7",
          },
          {
            type: "skin",
            id: "d8",
          },
          {
            type: "skin",
            id: "d9",
          },
          {
            type: "skin",
            id: "d10",
          },
          {
            type: "skin",
            id: "d11",
          },
          {
            type: "skin",
            id: "d12",
          },
          {
            type: "skin",
            id: "d13",
          },
          {
            type: "skin",
            id: "d14",
          },
          {
            type: "skin",
            id: "d15",
          },
          {
            type: "skin",
            id: "d16",
          },
          {
            type: "skin",
            id: "d17",
          },
          {
            type: "skin",
            id: "d18",
          },
          {
            type: "skin",
            id: "d19",
          },
          {
            type: "skin",
            id: "d20",
          },
        ],
        hair: [
          {
            type: "hair",
            id: "h1brown",
          },
          {
            type: "hair",
            id: "h1cherry",
          },
          {
            type: "hair",
            id: "h1aqua",
          },
          {
            type: "hair",
            id: "h1black",
          },
          {
            type: "hair",
            id: "h1dred",
          },
          {
            type: "hair",
            id: "h1dgreen",
          },
          {
            type: "hair",
            id: "h2purple",
          },
          {
            type: "hair",
            id: "h2bluegreen",
          },
          {
            type: "hair",
            id: "h3blue",
          },
          {
            type: "hair",
            id: "h3orange",
          },
        ],
        eyes: [
          {
            type: "eyes",
            id: "e4blue",
          },
          {
            type: "eyes",
            id: "e4purple",
          },
          {
            type: "eyes",
            id: "e4bpink",
          },
          {
            type: "eyes",
            id: "e4mystery",
          },
          {
            type: "eyes",
            id: "e4red",
          },
          {
            type: "eyes",
            id: "e4green",
          },
          {
            type: "eyes",
            id: "e4redder",
          },
          {
            type: "eyes",
            id: "e4dreampurple",
          },
        ],
        top: [
          {
            type: "top",
            id: "t1black",
          },
          {
            type: "top",
            id: "t1blue",
          },
        ],
        bottom: [
          {
            type: "bottom",
            id: "b0black",
          },
          {
            type: "bottom",
            id: "b0blue",
          },
          {
            type: "bottom",
            id: "b0grey",
          },
        ],
        shoes: [],
        accessories: [],
        misc: [],
      },
      user: {
        name: "friend",
      },
    };
  }

  handleEquip(e) {
    let newEquip = this.state.create.currentEquip.slice();
  }
  // avotype nested state?
  // use nested components instead?

  render() {
    return (
      <>
        <div className="container">
          <Header />
          <Nav name={this.state.user.name} />
          <div className="content">
            <CharacterCreation
              currentEquip={this.state.currentEquip}
              inventory={this.state.inventory}
            />
          </div>
          <Footer />
        </div>
      </>
    );
  }
}

export default StorySeaApp;
